<template>
  <form
    @submit.prevent="search"
    :class="`${
      advanced ? 'rounded-3xl' : 'rounded-3xl md:rounded-[3rem]'
    }    bg-white `"
  >
    <div
      :class="`border-neutral-lighter flex items-center  border-dotted text-left max-md:flex-wrap md:px-3 ${
        advanced ? 'md:border-b-2' : ''
      }`"
    >
      <div
        class="border-neutral-lighter w-full border-dotted px-8 py-4 max-md:border-b-2 md:border-r-2"
      >
        <label :for="startId" class="text-secondary w-full cursor-pointer"
          >Départ</label
        >
        <div class="text-secondary flex items-center">
          <icon icon="pin-2" class="mr-2 h-4 w-4"></icon>

          <AutoComplete
            class="search-form-text-input"
            forceSelection
            placeholder="Départ à"
            v-model="origin"
            :optionLabel="(o) => `${o.name} (${o.zip_code})`"
            :suggestions="originAutocomplete"
            @complete="searchOriginCities"
          >
            <template #option="slotProps">
              {{ slotProps.option.name }} ({{ slotProps.option.zip_code }})
            </template>
          </AutoComplete>
        </div>
      </div>
      <div
        class="border-neutral-lighter w-full border-dotted px-8 py-4 max-md:border-b-2 md:border-r-2"
      >
        <label :for="endId" class="text-ternary w-full cursor-pointer"
          >Destination</label
        >
        <div class="text-ternary flex items-center">
          <icon icon="pin-2" class="mr-2 h-4 w-4"></icon>

          <AutoComplete
            class="search-form-text-input"
            forceSelection
            placeholder="Arrivée à"
            v-model="destination"
            :optionLabel="(o) => `${o.name} (${o.zip_code})`"
            :suggestions="destinationAutocomplete"
            @complete="searchDestinationCities"
          >
            <template #option="slotProps">
              {{ slotProps.option.name }} ({{ slotProps.option.zip_code }})
            </template>
          </AutoComplete>
        </div>
      </div>

      <div
        class="border-neutral-lighter w-full border-dotted px-8 py-4 max-md:border-b-2"
      >
        <label :for="dateId" class="text-fire w-full cursor-pointer"
          >Date</label
        >

        <div class="text-fire flex items-center">
          <icon icon="calendrier" class="mr-2 h-4 w-4"></icon>
          <Calendar
            dateFormat="dd/mm/yy"
            v-model="date"
            class="search-form-text-input"
            placeholder="Date de départ"
          />
        </div>
      </div>
      <div
        v-if="!advanced"
        class="max-md:w-full max-md:px-5 max-md:py-8 max-md:text-center"
      >
        <rounded-button
          class="max-md:mx-auto max-md:px-12"
          icon="loupe"
          design="3"
          type="submit"
          size="lg"
          :disabled="!formValid"
          >Rechercher
        </rounded-button>
      </div>
    </div>
    <div
      v-if="advanced"
      class="w-full grid-cols-3 items-center gap-8 py-12 max-md:px-12 md:px-24 lg:grid"
    >
      <form-select
        class="col-span-2 max-lg:mb-5"
        label="Type de transport"
        :options="[
          { value: null, text: 'Choisir un type' },
          {
            value: 'before_coffin',
            text: 'Transport avant et après mise en bière (Frigorifique)',
          },
          {
            value: 'after_coffin',
            text: 'Transport après mise en bière',
          },
        ]"
      ></form-select>
      <rounded-button
        class="max-lg:mx-auto max-md:mx-auto max-md:px-12"
        icon="loupe"
        design="3"
        type="submit"
        size="lg"
        :disabled="!formValid"
        >Rechercher mon trajet
      </rounded-button>
    </div>
  </form>
</template>

<script setup>
import { v4 as uuidv4 } from "uuid";
import { defineProps, ref, computed, defineEmits } from "vue";
import _ from "lodash";
const dayjs = useDayjs();
const { $api, $toast } = useNuxtApp();
const router = useRouter();
import { onMounted } from "vue";

const props = defineProps({
  // Définissez vos props ici
  advanced: {
    type: Boolean,
    default: false,
  },
  originId: {
    type: Number,
  },
  destinationId: {
    type: Number,
  },
  dateInput: {
    type: Date,
  },
});
const startId = ref(uuidv4());
const endId = ref(uuidv4());
const dateId = ref(uuidv4());
const origin = ref();
const destination = ref();
const destinationAutocomplete = ref([]);
const originAutocomplete = ref([]);
const date = ref();
const emit = defineEmits(["search"]);
const fetchingDestinationCities = ref(false);
const fetchingOriginCities = ref(false);

// watch date input and set date ref when prop change

const updateFields = () => {
  let promises = [];

  if (props.originId) {
    promises.push(
      getCityById(props.originId)
        .then((result) => {
          origin.value = result;
        })
        .catch(() => {
          $toast.error("Une erreur est survenue");
        })
    );
  }
  if (props.destinationId) {
    promises.push(
      getCityById(props.destinationId)
        .then((result) => {
          destination.value = result;
        })
        .catch(() => {
          $toast.error("Une erreur est survenue");
        })
    );
  }
  if (props.dateInput) {
    date.value = dayjs(props.dateInput).toDate();
  }

  return Promise.all(promises).then(() => {
    emit("initSearch", {
      destination: destination.value,
      origin: origin.value,
    });
  });
};

onMounted(() => {
  updateFields();
});

const formValid = computed(() => {
  return (
    origin.value &&
    destination.value &&
    date.value &&
    (dayjs(date.value).isSame(dayjs(), "day") ||
      dayjs(date.value).isAfter(dayjs(), "day"))
  );
});

const searchOriginCities = _.debounce((searchQuery) => {
  fetchingOriginCities.value = true;
  autocompleteCityRequest(searchQuery.query)
    .then((result) => {
      originAutocomplete.value = result;
    })
    .finally(() => {
      fetchingOriginCities.value = false;
    });
}, 300);

const searchDestinationCities = _.debounce((searchQuery) => {
  fetchingDestinationCities.value = true;
  autocompleteCityRequest(searchQuery.query)
    .then((result) => {
      destinationAutocomplete.value = result;
    })
    .finally(() => {
      fetchingDestinationCities.value = false;
    });
}, 300);

const autocompleteCityRequest = (input) => {
  if (!input) {
    return [];
  }
  return $api
    .call("get", $api.route("cities.index"), null, {
      q: input,
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
};

const getCityById = (id) => {
  return $api
    .call("get", $api.route("cities.show", { city: id }))
    .then((payload) => {
      return payload;
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {});
};
const search = (e) => {
  e.preventDefault();

  if (!formValid.value) {
    $toast.error("Veuillez remplir tous les champs");
    return;
  }

  emit("search", {
    date: date.value,
    destination: destination.value,
    origin: origin.value,
  });
};

defineExpose({
  updateFields,
});
</script>

<style lang="css">
.search-form-text-input input {
  @apply text-primary placeholder-neutral-lighter w-full border-none border-transparent px-0 py-0 text-[1.125rem] outline-none  ring-0 focus:border-transparent focus:ring-0 focus:enabled:shadow-none focus:enabled:drop-shadow-none;
}

.search-form-text-input.p-autocomplete {
  @apply w-full;
}

.p-autocomplete-items {
  @apply text-primary bg-white;
}
</style>
